import { Button, Textarea } from "@sumit-platforms/ui-bazar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TranslationPrompts } from "@sumit-platforms/types";
import { Box } from "@mui/material";

interface Props {
  promptSettings?: TranslationPrompts;
  updateLanguage: (promptValue: string) => void;
  deleteLanguage: () => void;
}

const ClientSettingsLangPrompt = ({
  promptSettings,
  updateLanguage,
  deleteLanguage,
}: Props) => {
  const { t } = useTranslation();
  const [prompt, setPrompt] = useState<string>("");

  useEffect(() => {
    setPrompt(promptSettings?.prompt || "");
  }, [promptSettings]);

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    setPrompt(value);
    updateLanguage(value);
  };

  return (
    <Box
      padding={2}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      className="ClientSettingsLangPrompt"
    >
      <Textarea
        placeholder={t("prompt")}
        value={prompt}
        onChange={handlePromptChange}
      />
      <Box mt={2}>
        <Button variant="outlined" onClick={deleteLanguage}>
          {t("delete_language")}
        </Button>
      </Box>
    </Box>
  );
};

export default ClientSettingsLangPrompt;
