import React, { useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  SxProps,
  Theme,
} from "@mui/material";
import classNames from "classnames";

import "./Pager.scss";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-light-svg-icons";

interface PagerProps {
  pages: {
    pageTab: string;
    pageContent: JSX.Element;
  }[];
  addPage?: () => void;
  sx?: SxProps<Theme>;
}

export const Pager = ({ pages, sx, addPage }: PagerProps) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleOnChangeCurrentPage = (pageIndex: number) => {
    setCurrentPage(pageIndex);
  };

  return (
    <Grid className={"Pager"} container sx={sx}>
      <Grid item xs={3}>
        <List disablePadding className="pagerPageList">
          {pages.map((p, i: number) => (
            <ListItem disablePadding key={i + "nonstep"}>
              <ListItemButton
                onClick={() => handleOnChangeCurrentPage(i)}
                className={classNames([
                  "pagerPageListButton",
                  { current: currentPage === i },
                ])}
              >
                {p.pageTab}
              </ListItemButton>
            </ListItem>
          ))}
          {addPage && (
            <ListItem disablePadding key={"addPage"}>
              <ListItemButton onClick={addPage} className={"addPage"}>
                <Box display={"flex"} gap={1}>
                  <FontAwesomeIcon icon={faPlus} />
                  <Box>{t("add")}</Box>
                </Box>
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={9} className="pageWrapper">
        {pages && pages[currentPage] && pages[currentPage].pageContent}
      </Grid>
    </Grid>
  );
};
