import _ from "lodash";
import { Pager, SelectLanguageModal } from "@sumit-platforms/ui-bazar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../../../../../store/globalData";
import { useModal } from "@sumit-platforms/ui-bazar/store";
import {
  ClientSettingsPrompts,
  TranslationPrompts,
  Languages,
} from "@sumit-platforms/types";
import ClientSettingsLangPrompt from "./ClientSettingsLangPrompt";

interface Props {
  prompts?: ClientSettingsPrompts;
  onUpdate: (updatedPrompts: ClientSettingsPrompts) => void;
}

const ClientSettingsPromptsPager = ({ prompts, onUpdate }: Props) => {
  const { t } = useTranslation();
  const { languages } = useGlobalData();
  const [localPrompts, setLocalPrompts] = useState<
    ClientSettingsPrompts | undefined
  >(prompts);
  const [translateToLangs, setTranslateToLangs] = useState<string[]>([]);
  const { setModalContent, clearModalContent, setModalType } = useModal();

  useEffect(() => {
    setLocalPrompts(prompts);
    setTranslateToLangs(
      prompts?.translation ? _.keys(prompts.translation) : []
    );
  }, [prompts]);

  const handleOpenAddLanguageModal = () => {
    setModalContent(
      <SelectLanguageModal
        onCancel={clearModalContent}
        langOptions={languages
          .filter((l) => !translateToLangs.includes(l.value))
          .map((l) => l.value)}
        onConfirm={handleAddLanguage}
      />
    );
  };

  const handleAddLanguage = (lang: string) => {
    if (!localPrompts) {
      const newPrompts: ClientSettingsPrompts = {
        translation: { [lang]: { prompt: "" } } as Record<
          keyof Languages,
          TranslationPrompts
        >,
      };
      setLocalPrompts(newPrompts);
      onUpdate(newPrompts);
    } else {
      const currentTranslation =
        localPrompts.translation ||
        ({} as Record<keyof Languages, TranslationPrompts>);
      const updatedPrompts = {
        ...localPrompts,
        translation: {
          ...currentTranslation,
          [lang]: { prompt: "" },
        } as Record<keyof Languages, TranslationPrompts>,
      };
      setLocalPrompts(updatedPrompts);
      onUpdate(updatedPrompts);
    }

    setTranslateToLangs((prev) => [...prev, lang]);
    clearModalContent();
  };

  const handleDeleteLanguage = (lang: string) => {
    if (!localPrompts?.translation) return;

    const currentTranslation =
      localPrompts.translation ||
      ({} as Record<keyof Languages, TranslationPrompts>);
    const updatedTranslation = _.omit(currentTranslation, lang) as Record<
      keyof Languages,
      TranslationPrompts
    >;
    const updatedPrompts = {
      ...localPrompts,
      translation: updatedTranslation,
    };

    setLocalPrompts(updatedPrompts);
    onUpdate(updatedPrompts);
    setTranslateToLangs((prev) => prev.filter((l) => l !== lang));
  };

  const handleUpdateLanguagePrompt = (lang: string, promptValue: string) => {
    if (!localPrompts) {
      const newPrompts: ClientSettingsPrompts = {
        translation: { [lang]: { prompt: promptValue } } as Record<
          keyof Languages,
          TranslationPrompts
        >,
      };
      setLocalPrompts(newPrompts);
      onUpdate(newPrompts);
    } else {
      const currentTranslation =
        localPrompts.translation ||
        ({} as Record<keyof Languages, TranslationPrompts>);
      const updatedPrompts = {
        ...localPrompts,
        translation: {
          ...currentTranslation,
          [lang]: { prompt: promptValue },
        } as Record<keyof Languages, TranslationPrompts>,
      };
      setLocalPrompts(updatedPrompts);
      onUpdate(updatedPrompts);
    }
  };

  return (
    <Pager
      sx={{ minWidth: "850px", minHeight: "408px" }}
      pages={[
        {
          pageTab: t("translate_to"),
          pageContent: (
            <Pager
              sx={{ minWidth: "850px", minHeight: "408px" }}
              pages={translateToLangs.map((lang: string) => ({
                pageTab: lang,
                pageContent: (
                  <ClientSettingsLangPrompt
                    promptSettings={_.get(localPrompts, `translation.${lang}`)}
                    updateLanguage={(promptValue) =>
                      handleUpdateLanguagePrompt(lang, promptValue)
                    }
                    deleteLanguage={() => handleDeleteLanguage(lang)}
                  />
                ),
              }))}
              addPage={() => handleOpenAddLanguageModal()}
            />
          ),
        },
      ]}
    />
  );
};

export default ClientSettingsPromptsPager;
