import { Button, Pager } from "@sumit-platforms/ui-bazar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ClientSettingsPromptsPager from "./Prompts/ClientSettingsPromptsPager";
import { Client, ClientSettings } from "@sumit-platforms/types";
import { Box, Grid } from "@mui/material";

interface Props {
  client: Client | null;
  settings?: ClientSettings;
  onUpdate: (settings: Client["settings"]) => Promise<void>;
}

const ClientSettingsTab = ({
  client,
  settings: initialSettings,
  onUpdate,
}: Props) => {
  const { t } = useTranslation();
  const [settings, setSettings] = useState<ClientSettings | undefined>(
    initialSettings
  );
  const [loading, setLoading] = useState<boolean>(false);

  const handleUpdateSettings = async () => {
    if (!settings || !client?.idClient) return;

    setLoading(true);
    try {
      await onUpdate(settings);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrompts = (updatedPrompts: any) => {
    setSettings((prev) =>
      prev ? { ...prev, prompts: updatedPrompts } : undefined
    );
  };

  return (
    <Grid className="ClientSettingsTab" display="flex" flexDirection="column">
      <Box style={{ background: "white" }} borderRadius={2} overflow={"hidden"}>
        <Pager
          sx={{ maxWidth: "650px", minHeight: "408px" }}
          pages={[
            {
              pageTab: t("prompts"),
              pageContent: (
                <ClientSettingsPromptsPager
                  prompts={settings?.prompts}
                  onUpdate={handleUpdatePrompts}
                />
              ),
            },
          ]}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" width={"100%"} mt={2}>
        <Button onClick={handleUpdateSettings} disabled={loading}>
          {loading ? t("saving") : t("save")}
        </Button>
      </Box>
    </Grid>
  );
};

export default ClientSettingsTab;
